export function toggleFilterMobile() {
	// Generalized toggle function for filters
	function setupToggle(buttonClass, boxClass, backdropClass, closeClass) {
		// Toggle box and backdrop on button click
		$(buttonClass).on('click', function () {
			$(boxClass).toggleClass('active');
			$(backdropClass).fadeToggle();
		});
		// Close box and backdrop on close button click
		$(closeClass).on('click', function () {
			$(boxClass).removeClass('active');
			$(backdropClass).fadeOut();
		});
		// Close box and backdrop if clicked outside of the box or button
		$(document).on('click', function (e) {
			if (!$(e.target).closest(buttonClass).length && !$(e.target).closest(closeClass).length && !$(e.target).closest(boxClass).length) {
				$(boxClass).removeClass('active');
				$(backdropClass).fadeOut();
			}
		});
	}

	// Set up toggles for each type
	setupToggle('.button-filter-mobile', '.box-filter', '.backdrop-filter', '.close-filter');
	setupToggle('.button-service-category-mobile', '.box-service-category', '.backdrop-service-category', '.close-service-category');
	setupToggle('.button-service-other-mobile', '.box-service-other', '.backdrop-service-other', '.close-service-other');
}
