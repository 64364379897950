import { headerSearch } from "../../plugins/ComponentsUi/HeaderSearch/HeaderSearch";
import { detectCloseElement } from "./helper";
/*==================== Header ====================*/
/**
 * @param header
 */
const vw = $(window).width();
export const header = {
	scrollActive: function () {
		let height = $("header").height();
		let windowHeight = $(window).height();
		if ($(window).scrollTop() > height) {
			$("header").addClass("active");
		} else {
			$("header").removeClass("active");
		}
		if ($(window).scrollTop() > windowHeight - height) {
			$('.book-tour-pin').addClass('active');
			$('.book-tour-pin') ? $('.cta-fixed').addClass('hidden') : $('.cta-fixed').removeClass('hidden');
		} else {
			$('.book-tour-pin').removeClass('active');
			$('.cta-fixed').removeClass('hidden');
		}
	},
	mobile: function () {
		$(".header-hamburger").on("click", function () {
			$(this).toggleClass("active");
			$('.mobile-wrap').toggleClass("active");
			$('.backdrop-mobile').fadeToggle();
		});
		$(document).on('click', function (e) {
			if (!$(e.target).closest($('.mobile-wrap')).length && !$(e.target).closest($('.header-hamburger')).length) {
				$('.mobile-wrap').removeClass('active');
				$('.backdrop-mobile').fadeOut();
				$('.header-hamburger').removeClass('active');
			}
		});
		// mapping menu
		const mainMenu = $(".header-bot .main-menu").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "prependTo",
			desktopWrapper: ".header-bot .header-right .button-cart ",
			desktopMethod: "insertBefore",
			breakpoint: 1199.98,
		});
		const menuTop = $(".header-top .main-menu").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "appendTo",
			desktopWrapper: ".header-top .header-right ",
			desktopMethod: "prependTo",
			breakpoint: 1199.98,
		});
		const latestNews = $(".latest-news").mapping({
			mobileWrapper: "main",
			mobileMethod: "appendTo",
			desktopWrapper: ".news-right-import",
			desktopMethod: "appendTo",
			breakpoint: 0,
		});
		const mostNews = $(".most-news").mapping({
			mobileWrapper: "main",
			mobileMethod: "appendTo",
			desktopWrapper: ".news-right-import",
			desktopMethod: "appendTo",
			breakpoint: 0,
		});
		const randomNews = $(".random-news").mapping({
			mobileWrapper: "main",
			mobileMethod: "appendTo",
			desktopWrapper: ".news-right-import",
			desktopMethod: "appendTo",
			breakpoint: 0,
		});
		const tagsImport = $(".tags-import").mapping({
			mobileWrapper: "main",
			mobileMethod: "appendTo",
			desktopWrapper: ".pages",
			desktopMethod: "insertAfter",
			breakpoint: 0,
		});
		// add arrow dropdown
		if (window.matchMedia("(max-width: 1199.98px)").matches) {
			$('<i class="toggle-menu fa-solid fa-chevron-down"></i>').insertAfter( "li.has-children > a");
			$("li.has-children .toggle-menu").on("click", function () {
				$(this).toggleClass('active')
				$(this).parent().find("> .sub-menu").slideToggle();
			});
		}
	},
	init: function () {
		headerSearch();
		header.scrollActive();
		header.mobile();
	},
};
document.addEventListener(
	"scroll",
	function (e) {
		header.scrollActive();
	},
	true
);
