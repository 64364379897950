export function tocInit() {
	// $("#toc").toc({ content: "div.full-content", headings: "h2,h3,h4" })
	$('.full-content :header').each(function (index) {
		var headingText = $(this).text().trim();
		$(this).attr('id', removeVietnameseDiacritics(headingText));
	});
	if ($('.toc-wrap').length) {
		$('.toc-wrap .toc-title').on('click', function () {
			$(this).toggleClass('active');
			$('.toc-wrap .toc-init').slideToggle();
		});

		tocbot.init({
			// Where to render the table of contents.
			tocSelector: '.toc-init',
			// Where to grab the headings to build the table of contents.
			contentSelector: '.full-content',
			// Which headings to grab inside of the contentSelector element.
			headingSelector: 'h2, h3, h4',
			// For headings inside relative or absolute positioned containers within content.
			hasInnerContainers: true,
			headingsOffset: 150,
			scrollSmoothOffset: -150,
		});
		// tocbot.refresh({ ...tocbot.tocOptions, hasInnerContainers: true })

		// var firstToc = $('.js-toc .toc-list-item:first-child > a').html();

		// $('.toc-pin-section .toc-active').html(firstToc);

		// $('.toc-pin-wrap .toc-toggle').on('click', function () {
		// 	$(this).toggleClass('active').next().slideToggle();
		// });
		// $('.toc-pin-wrap .toc-list a').on('click', function () {
		// 	$('.toc-pin-wrap .toc-toggle').removeClass('active').next().slideUp();
		// });
	}
}

function removeVietnameseDiacritics(str) {
	var diacriticsMap = {
		'á': 'a', 'à': 'a', 'ả': 'a', 'ã': 'a', 'ạ': 'a',
		'ắ': 'a', 'ằ': 'a', 'ẳ': 'a', 'ẵ': 'a', 'ặ': 'a',
		'ấ': 'a', 'â': 'a', 'ầ': 'a', 'ẩ': 'a', 'ẫ': 'a', 'ậ': 'a',
		'é': 'e', 'è': 'e', 'ẻ': 'e', 'ẽ': 'e', 'ẹ': 'e',
		'ế': 'e', 'ề': 'e', 'ể': 'e', 'ễ': 'e', 'ệ': 'e',
		'í': 'i', 'ì': 'i', 'ỉ': 'i', 'ĩ': 'i', 'ị': 'i',
		'ó': 'o', 'ò': 'o', 'ỏ': 'o', 'õ': 'o', 'ọ': 'o',
		'ố': 'o', 'ồ': 'o', 'ổ': 'o', 'ỗ': 'o', 'ộ': 'o',
		'ớ': 'o', 'ờ': 'o', 'ở': 'o', 'ỡ': 'o', 'ợ': 'o',
		'ú': 'u', 'ù': 'u', 'ủ': 'u', 'ũ': 'u', 'ụ': 'u',
		'ư': 'u', 'ứ': 'u', 'ừ': 'u', 'ử': 'u', 'ữ': 'u', 'ự': 'u',
		'ý': 'y', 'ỳ': 'y', 'ỷ': 'y', 'ỹ': 'y', 'ỵ': 'y',
		'đ': 'd', 'ă': 'a',
		'Á': 'A', 'À': 'A', 'Ả': 'A', 'Ã': 'A', 'Ạ': 'A',
		'Ắ': 'A', 'Ằ': 'A', 'Ẳ': 'A', 'Ẵ': 'A', 'Ặ': 'A',
		'Ấ': 'A', 'Â': 'A', 'Ầ': 'A', 'Ẩ': 'A', 'Ẫ': 'A', 'Ậ': 'A',
		'É': 'E', 'È': 'E', 'Ẻ': 'E', 'Ẽ': 'E', 'Ẹ': 'E',
		'Ế': 'E', 'Ề': 'E', 'Ể': 'E', 'Ễ': 'E', 'Ệ': 'E',
		'Í': 'I', 'Ì': 'I', 'Ỉ': 'I', 'Ĩ': 'I', 'Ị': 'I',
		'Ó': 'O', 'Ò': 'O', 'Ỏ': 'O', 'Õ': 'O', 'Ọ': 'O',
		'Ố': 'O', 'Ồ': 'O', 'Ổ': 'O', 'Ỗ': 'O', 'Ộ': 'O',
		'Ớ': 'O', 'Ờ': 'O', 'Ở': 'O', 'Ỡ': 'O', 'Ợ': 'O',
		'Ú': 'U', 'Ù': 'U', 'Ủ': 'U', 'Ũ': 'U', 'Ụ': 'U',
		'Ư': 'U', 'Ứ': 'U', 'Ừ': 'U', 'Ử': 'U', 'Ữ': 'U', 'Ự': 'U',
		'Ý': 'Y', 'Ỳ': 'Y', 'Ỷ': 'Y', 'Ỹ': 'Y', 'Ỵ': 'Y',
		'Đ': 'D', 'Ă': 'A',
	};

	// Remove diacritics
	var withoutDiacritics = str.replace(/[^\u0000-\u007E]/g, function (a) {
		return diacriticsMap[a] || a;
	});

	// Replace spaces with hyphens and convert to lowercase
	return withoutDiacritics.replace(/ /g, '-').toLowerCase();
}
